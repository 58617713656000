import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { equals } from 'ramda'
import classNames from 'classnames'
import ReactSVG from 'react-svg'

import RatingsReasonsTooltip from '../../shared/ratings-tooltip'
import { getDurationTextFromMinutes } from '../../../lib/date-time'
import { isRentable, getLowestPrice } from '../../../lib/content-item'

import HeroImage from '../../hero-image'
import ContentVideoDisplay from '../../../containers/shared/content-video-display'
import AddOrRemoveFromListButton from '../../../containers/add-or-remove-from-list-button'
import styles from './title-detail.css'
import { MoreInfoComponent as MoreInfo } from '../../shared/more-info'
import UpcomingEpisode from '../../shared/upcoming-episode'

import arrowDown from '../../../../images/down-arrow.svg'
import rentalBadge from '../../../../images/neon/rental-badge-kids.png'

import CONTENT_ITEM_FRAGMENT from '../../../../graphql/fragments/content-item.gql'
import CONTENT_ITEM_LIGHT_FRAGMENT from '../../../../graphql/fragments/content-item-light.gql'
import {
  COMPONENT_NAME_TITLE_DETAIL,
  COMPONENT_NAME_TITLE_DETAIL_KIDS
} from '../../../lib/analytics/custom-dimensions'
import {
  getDetailsFullUrl,
  isKids
} from '../../../lib/utils'
import { getLocationBaseWithHref } from '../../../lib/location'
import { openGraphMetaImages } from '../../../lib/open-graph/images'
import { openGraphMetaActors } from '../../../lib/open-graph/actors'
import { openGraphMetaDirectors } from '../../../lib/open-graph/directors'
import { openGraphMetaTags } from '../../../lib/open-graph/tags'
import { HelmetIfNoModal } from '../../helmet-if-no-modal'
import infoIcon from '../../../../images/info.svg'
import { Rating } from '../../rating'

export const boldRegex = /\*\*\*(.+)\*\*\*/

function TitleDetailKids({
  rentalStatus,
  rentalOnClick,
  contentItem,
  myList,
  theme,
  moreInfoExpanded,
  toggleMoreInfoExpanded,
  showMoreInfoButton,
  notes
}) {
  const imageUri = contentItem.keyart && contentItem.keyart.uri
  const genres =
    contentItem.genres &&
    contentItem.genres.slice(0, 1).map(genre => (
      <span className={styles.genre} key={genre}>
        {genre}
      </span>
    ))
  const year = contentItem.year ? contentItem.year : null
  const duration = contentItem.duration
    ? getDurationTextFromMinutes(contentItem.duration)
    : null
  const { rating, reason } = contentItem.rating
  const lowestPrice = getLowestPrice(contentItem)
  const { isRental } = contentItem

  const brand = 'NEON'
  const fromPrice = isRental && lowestPrice ? ` $${lowestPrice}` : ''

  const title = `Watch ${contentItem.title} Online with ${brand}${fromPrice}`

  const displayRating = () => {
    if (contentItem.rating.ratingSystem === 'BSV') {
      return (
        <RatingsReasonsTooltip reason={reason}>
          <span
            className={classNames({
              [styles.rating]: true,
              [styles.G]: equals('G', rating),
              [styles.PG]: equals('PG', rating)
            })}
            data-lbx-e2e={rating}
          >
            {rating}
          </span>
        </RatingsReasonsTooltip>
      )
    }
    return (
      <Rating size="medium" rating={contentItem.rating} />
    )
  }

  return (
    <div>
      <div className={styles.titleDetail}>
        <HelmetIfNoModal>
          <meta property="og:title" content={title} />
          <meta property="og:type" content="video.movie" />
          <meta property="og:url" content={getLocationBaseWithHref()} />
          <meta property="og:video:release_date" content={contentItem.year} />
          <meta
            property="og:video:duration"
            content={contentItem.duration * 60}
          />
          {openGraphMetaImages(contentItem.images)}
          {openGraphMetaActors(contentItem.actors)}
          {openGraphMetaDirectors(contentItem.directors)}
          {openGraphMetaTags(contentItem.genres)}
          <meta name="description" property="og:description" content={title} />
          <link rel="canonical" href={getDetailsFullUrl(contentItem)} />
          <title>{title}</title>
        </HelmetIfNoModal>

        <div className={styles.kidsHeroImageWrapper}>
          <HeroImage
            className={styles.heroImage}
            image={imageUri}
            title={contentItem.title}
          />

          {isRental && (
            <img
              src={rentalBadge}
              alt="Rental Content"
              className={styles.rentalBadge}
            />
          )}

          <ContentVideoDisplay
            className={styles.contentVideoWrapper}
            imageWrapperClass={styles.contentVideoImageWrapper}
            iconWrapperClass={styles.contentVideoIconWrapper}
            imageClass={styles.contentVideoImage}
            contentItem={contentItem}
            isTitleKids
          />
        </div>
        <div className={styles.header}>
          <div className={styles.leftHeader}>
            <h1 className={styles.title} data-lbx-e2e={contentItem.title}>
              {contentItem.title}
            </h1>
            <div className={styles.subHeader}>
              {rating && displayRating()}
              <span>{genres}</span>
              {year && (
                <span>
                  |
                  {year}
                </span>
              )}
              {duration && (
                <span>
                  |
                  {duration}
                </span>
              )}
            </div>
            {contentItem.isComingSoon && contentItem.start && (
              <UpcomingEpisode
                startDate={contentItem.start}
                isRental={contentItem.isRental}
                title
              />
            )}
          </div>
          <div className={styles.rightHeader}>
            <div className={styles.addOrRemoveButtonWrapper}>
              <AddOrRemoveFromListButton
                className={styles.addOrRemoveButton}
                iconWrapperClassName={styles.addOrRemoveButtonIcon}
                contentItem={contentItem}
                myList={myList}
                renderedInComponent={
                  isKids(theme)
                    ? COMPONENT_NAME_TITLE_DETAIL_KIDS
                    : COMPONENT_NAME_TITLE_DETAIL
                }
                theme={theme}
              />
            </div>
            {showMoreInfoButton && (
              <button
                className={styles.moreInfoButton}
                onClick={toggleMoreInfoExpanded}
              >
                {moreInfoExpanded ? 'Less' : 'More'}
                {' '}
                Info
                <div
                  className={classNames(styles.moreInfoButtonWrapper, {
                    [styles.open]: moreInfoExpanded
                  })}
                >
                  <ReactSVG
                    beforeInjection={svg => {
                      svg.setAttribute(
                        'class',
                        classNames(styles.moreInfoButtonIcon)
                      )
                    }}
                    src={arrowDown}
                  />
                </div>
              </button>
            )}
          </div>
          <MoreInfo
            className={styles.moreInfoWrapper}
            synopsisClassName={styles.seriesSynopsis}
            showMoreInfoButton={false}
            isExpanded={moreInfoExpanded}
            contentItem={contentItem}
            theme={theme}
          />
        </div>
      </div>

      {isRental && isRentable(rentalStatus) && (
        <div className={styles.movieWrapper}>
          <div
            className={classNames({
              [styles.movieDisclaimer]: notes.length > 1
            })}
          >
            {notes.length > 1 && (
              <ReactSVG
                src={infoIcon}
                beforeInjection={svg => {
                  svg.setAttribute('class', styles.disclaimerIcon)
                }}
                className={styles.svgWrapper}
              />
            )}
            <div>
              {notes.length > 1 &&
                notes.map(note => {
                  if (boldRegex.test(note)) {
                    const match = boldRegex.exec(note)
                    return (
                      <p>
                        <b>{match[1]}</b>
                      </p>
                    )
                  }

                  return <p key={note}>{note}</p>
                })}
            </div>
          </div>
          <a className={styles.getMovieButton} onClick={rentalOnClick}>
            Get This Movie
          </a>
        </div>
      )}
    </div>
  )
}

TitleDetailKids.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  myList: PropTypes.arrayOf(propType(CONTENT_ITEM_LIGHT_FRAGMENT)).isRequired,
  theme: PropTypes.string.isRequired,
  rentalStatus: PropTypes.string.isRequired,
  rentalOnClick: PropTypes.func.isRequired,
  moreInfoExpanded: PropTypes.bool.isRequired,
  toggleMoreInfoExpanded: PropTypes.func.isRequired,
  showMoreInfoButton: PropTypes.bool.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default TitleDetailKids
